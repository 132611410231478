/*-----------------------------------------------------------------------------------

    Template Name: Robic
    Template URI: http://power-boosts.com
    Description: This is html5 template
    Author: Power-Boosts
    Author URI: http://power-boosts.com
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    1. Theme Default CSS (body, link color, section etc)
    2. Header Area
    3. Slider Area
    4. Feature Area
    5. Service Area
    6. Video Area
    7. Features Carousel Area
    8. Pricing Area
    9. Clients Area
    10. Blog Area
    11. Download Area
    12. Contact Area
    13. Footer Area
    14. Image, Solid, Gradient, Transparent, Video Background Area
    15. Light Section Style
    16. Layout Two Style
    17. Scroll Up Start
-----------------------------------------------------------------------------------*/

/*----------------------------------------*/
/*  1. Theme default CSS
/*----------------------------------------*/
* {
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
}

.floatleft {
    float: left !important;
}

.floatright {
    float: right !important;
}

.floatnone {
    float: none !important;
}

.alignleft {
    text-align: left !important;
}

.alignright {
    text-align: right !important;
}

.aligncenter {
    text-align: center !important;
}

.no-display {
    display: none;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

a:focus, button:focus {
    outline: 0px solid
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
    vertical-align: top;
}

.fix {
    overflow: hidden
}

p {
    font-family: "Segoe UI";
    font-weight: 300;
    line-height: 24px;
    margin: 0 0 15px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px;
}

a {
    transition: all 0.3s ease 0s;
    text-decoration: none;
}

a:hover {
    color: #fff;
    text-decoration: none;
}

a:active, a:hover, a:focus {
    outline: 0 none;
    text-decoration: none
}

ul {
    list-style: outside none none;
    margin: 0;
    padding: 0
}

.clear {
    clear: both
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

body {
    color: #FFF;
    font-family: "Segoe UI";
    font-size: 16px;
    line-height: 20px;
    text-align: left;
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI'), url('../fonts/segoeui.woff') format('woff');
}

.section-dark-one {
    background: #252830
}

.section-dark-two {
    background: #1E2127
}

.section-dark-three {
    background: #191C21
}

.section-light-one {
    background: #F4F3F3
}

.section-title {
    margin-bottom: 30px
}

.section-title > h1 {
    color: #ffffff;
    font-family: "Segoe UI Black";
    font-size: 48px;
    margin-bottom: 8px;
}

.section-title > h1 span {
    font-family: "Segoe UI Light";
}

.section-title > p {
    font-weight: 400;
    margin-bottom: 16px;
}

.section-title.light > h1 {
    color: #252830
}

.section-title.light > h1 span {
    color: #242e4f
}

/*----------------------
	2. Header Area
------------------------*/
.logo a {
    display: block;
    margin-left: -18px;
    position: relative;
}

#main-menu {
    left: 0;
    position: absolute;
    right: 0;
    top: 27px;
    z-index: 999999;
}

#main-menu.stick {
    background: url("../img/bg_menu.png");
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    padding: 10px 0;
    position: fixed;
    top: 0;
}

.main-menu nav ul li {
    display: inline-block;
    float: left;
}

.main-menu nav ul li a {
    color: #FFF;
    display: block;
    font-size: 14px;
    font-weight: 800;
    line-height: 14px;
    padding: 24px 8px;
    position: relative;
    text-transform: uppercase;
}

.main-menu nav ul li.active a, .main-menu nav ul li a:hover {
    /*color: #fff*/
    color: #d6a54f;
}

/*----------------------------------------*/
/*  3. Slider Area
/*----------------------------------------*/
.banner-content {
    height: 100%;
    position: relative;
}

.banner-content .text-content {
    left: 0;
    margin: auto;
    max-width: 80%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 24%;
    color: #fff
}

.banner-content h1 {
    animation: 1500ms ease-in-out 0s normal none 1 running bounceInLeft;
    font-family: "Segoe UI Black";
    font-size: 30px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.banner-content h2 {
    animation: 2000ms ease-in-out 0s normal none 1 running bounceInLeft;
    font-family: "Segoe UI Black";
    font-size: 58px;
    line-height: 56px;
    margin-bottom: 0;
    text-transform: uppercase;
}

.banner-content h3 {
    animation: 2500ms ease-in-out 0s normal none 1 running bounceInLeft;
    font-family: "Segoe UI Black";
    font-size: 68px;
    line-height: 69px;
    margin-bottom: 0;
    text-transform: uppercase;
}

.banner-content p {
    animation: 3000ms ease-in-out 0s normal none 1 running bounceInLeft;
    color: #ffffff;
    font-family: "Neuton", serif;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
    padding: 5px;
}

.banner-content .banner-button {
    animation: 3500ms ease-in-out 0s normal none 1 running bounceInLeft;
    clear: both;
    display: block;
    height: 50px;
    line-height: 47px;
    margin-top: 36px;
}

.banner-content .banner-button a, a.button, button {
    border: 1px solid #d7d7d7;
    border-radius: 30px;
    color: #ffffff;
    display: inline-block !important;
    font-family: "Segoe UI";
    font-size: 12px;
    font-weight: bold;
    line-height: 46px;
    padding: 0 30px;
    text-transform: uppercase;
    transition: all 0.3s ease-in 0s;

    background: url("../img/bg_menu.png");
}

.banner-content .banner-button a:hover, a.button:hover {
    background-color: #c9634c;
    border: 1px solid #c9634c;
    color: #ffffff;
}

.nivo-controlNav {
    display: none
}

/*----------------------------------------*/
/*  4. Feature Area
/*----------------------------------------*/
.feature-area, .pagina-area {
    padding-bottom: 86px;
    padding-top: 88px;
}

.feature-text .p-title, .pagina-text .p-title {
    color: #959595;
    line-height: 14px;
    font-weight: bold;
}

.feature-text > h2, .pagina-text > h2 {
    color: #ffffff;
    /*font-family: "montserratultra_light";*/
    font-family: "Segoe UI Light";
    font-weight: lighter;
    font-size: 48px;
    line-height: 55px;
    margin: 0;
}

.feature-text > h1, .pagina-text > h1 {
    color: #ffffff;
    display: block;
    font-family: "Segoe UI";
    font-size: 59px;
    line-height: 61px;
    margin-bottom: 23px;
}

.feature-text a.button, .pagina-text a.button {
    margin-top: 25px
}

.app-image {
    margin-top: -140px;
    padding-left: 25px;
    position: relative;
    z-index: 99;
}


/*----------------------------------------*/
/*  5. Service Area
/*----------------------------------------*/
.service-area {
    padding-bottom: 62px;
    padding-top: 64px;
}

.service-text h4 {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 17px;
    text-transform: uppercase;
}

.service-text > p {
    margin-bottom: 0
}

.single-service {
    float: left;
    margin-top: 17px;
    position: relative;
    width: 33.33%;
}

.service-border {
    display: block;
    padding: 22px 27px 39px;
}

.service-border::before {
    background: #292c35 none repeat scroll 0 0;
    content: "";
    height: 93%;
    position: absolute;
    right: 0;
    top: 3.5%;
    width: 1px;
}

.service-area .single-service:nth-child(4) .service-border::before, .service-area .single-service:nth-child(5) .service-border::before {
    top: -3.5% !important;
}

.service-area .single-service:nth-child(3) .service-border::before, .service-area .single-service:nth-child(6) .service-border::before {
    display: none;
}

.service-border::after {
    background: #292c35 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 2.5%;
    position: absolute;
    width: 95%;
}

.service-area .single-service:nth-child(4) .service-border::after, .service-area .single-service:nth-child(5) .service-border::after, .service-area .single-service:nth-child(6) .service-border::after {
    display: none;
}

.service-icon {
    background: #c9634c none repeat scroll 0 0;
    border-radius: 100%;
    display: table;
    height: 71px;
    margin: 0 auto 22px;
    width: 71px;
    position: relative
}

.icon-middle {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%;
}

.service-icon::after {
    border: 6px solid #1b3545;
    border-radius: 500px;
    content: "";
    height: 83px;
    left: -6px !important;
    opacity: 0;
    position: absolute;
    top: -6px !important;
    transform: scale(1.5);
    transition: all 0.5s ease 0s;
    width: 83px;
}

.single-service:hover .service-icon::after {
    left: 0;
    opacity: 1;
    top: 0;
    transform: scale(1);
}

/*--------------------------------------
	6. Video Area
----------------------------------------*/
.video-area {
    position: relative
}

.video-overlay::after, .video-banner-overlay {
    background: #000;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

#video-bg {
    width: 100%;
}

.video-wrapper, .video-banner {
    height: 533px;
    overflow: hidden;
    position: relative;
}

.video-text {
    display: table;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
}

.table-cell {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    width: 100%;
}

#polina button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 2px solid #ffffff;
    border-radius: 100%;
    color: #ffffff;
    font-size: 20px;
    height: 60px;
    padding: 0;
    text-align: center;
    width: 60px;
    outline: none
}

#polina button:focus {
    outline: none
}

.video-text h1 {
    color: #c9634c;
    font-family: "Segoe UI";
    font-weight: bold;
    font-size: 48px;
    margin-top: 33px;
    z-index: 99;
}

.video-text h1 span {
    font-family: 'Segoe UI';
    font-weight: lighter;
    color: #fff
}

/*--------------------------------------
	7. Features Carousel Area
----------------------------------------*/
.features-carousel-area {
    padding-top: 100px;
    text-align: right;
}

.features-list > h1, .pagina-list > h1 {
    color: #ffffff;
    font-family: "Segoe UI";
    font-size: 48px;
    line-height: 37px;
    margin-bottom: 56px;
}

.single-features-list {
    margin-bottom: 10px;
}

.feature-list-text > h4, .pagina-list-text > h4 {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 3px;
}

.feature-list-img, .pagina-list-img {
    display: inline-block;
    float: right;
    margin-left: 20px;
    width: 34px;
}

.feature-list-text, .pagina-list-text {
    display: inline-block;
    margin-top: 4px;
}

.feature-list-carousel.owl-theme .owl-controls {
    margin-top: 15px;
    text-align: right;
}

.feature-list-carousel.owl-theme .owl-controls .owl-page span {
    background: #3a3e4b none repeat scroll 0 0;
    border: 2px solid #252830;
    height: 14px;
    margin: 3.5px;
    opacity: 1;
    position: relative;
    width: 14px;
    z-index: 2 !important;
}

.feature-list-carousel.owl-theme .owl-controls .owl-page {
    position: relative
}

.feature-list-carousel.owl-theme .owl-controls .owl-page::after {
    background: #959595 none repeat scroll 0 0;
    border-radius: 100%;
    content: "";
    display: none;
    height: 16px;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
    z-index: 1;
}

.feature-list-carousel.owl-theme .owl-controls .owl-page.active span, .feature-list-carousel.owl-theme .owl-controls .owl-page:hover span {
    background: #959595 none repeat scroll 0 0;
}

.feature-list-carousel.owl-theme .owl-controls .owl-page.active:after, .feature-list-carousel.owl-theme .owl-controls .owl-page:hover:after {
    display: block
}

.feature-app {
    margin-bottom: 20px;
    position: relative;
}

/*--------------------------------------
	8. Pricing Area
----------------------------------------*/
.pricing-area {
    padding-top: 152px
}

.pricing-area .section-title {
    margin-bottom: 78px
}

.pricing-area .section-title > p {
    margin-bottom: 20px
}

.pricing-packages .col-md-4 {
    padding: 0
}

.single-price-package {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #7e7f82;
    margin-bottom: -136px;
    padding: 23px 0 48px;
}

.price-title {
    text-align: center;
    border-bottom: 1px solid #f5f5f5;
}

.price-title > h3 {
    color: #c9634c;
    font-size: 20px;
}

.price-title > h1 {
    color: #081f2d;
    font-family: "montserratsemi_bold";
    font-size: 45px;
    margin-bottom: 0;
}

.price-title > p {
    color: #29323c;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 27px;
    text-transform: uppercase;
}

.price-list {
    padding-top: 60px
}

.price-list li {
    color: #898989;
    margin: 0 auto 32px;
    width: 139px;
}

.price-list img {
    margin-right: 16px
}

.price-btn, .buttons-load {
    width: 100%;
    text-align: center
}

.price-list .button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-color: #d7d7d7;
    color: #363636;
    margin-top: 18px
}

.price-list .button:hover {
    color: #fff;
    background: #c9634c;
    border-color: #c9634c;
}

.single-price-package.dark {
    background: #252830;
    border-color: #2C3038;
    padding: 62px 0 48px;
    margin-top: -39px
}

.single-price-package.dark .price-title {
    border-color: #2e2f33;
}

.single-price-package.dark .price-list li {
    color: #d0d0d0;
}

.single-price-package.dark .price-list .button, .blog-area button {
    /*background: #c9634c;*/
    /*border-color: #c9634c;*/
    /*color: #fff*/
}

.single-price-package.dark .price-list .button:hover, .blog-area button:hover {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-color: #d7d7d7;
    color: #fff;
}

/*--------------------------------------
	9. Clients Area
----------------------------------------*/
.clients-area {
    /*padding-top: 206px*/
}

.testimonial-image-slider {
}

.sin-testiImage {
    display: inline-block;
    margin-bottom: 50px;
    outline: none
}

.sin-testiImage img {
    border-radius: 50%;
    display: inline-block !important;
    height: 47px;
    margin-bottom: 30px;
    margin-top: 15px;
    width: 47px;
    cursor: pointer;
}

.sin-testiImage.slick-current img {
    height: 72px;
    margin-top: 0;
    opacity: 1;
    width: 72px;
}

.testimonial-text-slider h2 {
    color: #ebebeb;
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.sin-testiImage h2 span {
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}

.testimonial-text-slider {
    margin-bottom: 15px
}

.sin-testiText {
    outline: none
}

.sin-testiText p {
    color: #c3c3c3;
    font-weight: 400;
    margin-bottom: 32px;
}

.client-rating {
    margin-bottom: 24px
}

.client-rating i {
    color: #7d7d7d;
    font-size: 12px;
}

.client-rating i.color {
    color: #f7941d
}

.slick-prev, .slick-next {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    margin-top: -10px;
    outline: medium none;
    padding: 0;
    position: absolute;
    top: 24%;
    width: 20px;
}

.slick-prev {
    left: -30px
}

.slick-next {
    right: -30px
}

.slick-prev::before {
    background: url(../img/icon/arrow-left.png);
    height: 21px;
    width: 11px;
    content: "";
    left: 0;
    top: 0;
    position: absolute
}

.slick-prev:hover:before {
    background: url(../img/icon/arrow-left-hover.png)
}

.slick-next::before {
    background: url(../img/icon/arrow-right.png);
    height: 21px;
    width: 11px;
    content: "";
    right: 0;
    top: 0;
    position: absolute
}

.slick-next:hover:before {
    background: url(../img/icon/arrow-right-hover.png)
}

/*--------------------------------------
	10. Blog Area
----------------------------------------*/
.blog-area {
    padding-bottom: 81px;
    padding-top: 76px;
}

.blog-area .section-title > p {
    margin-bottom: 24px
}

.blog-area .col-md-4 {
    width: 100%
}

.blog-image a {
    position: relative;
    display: block
}

.blog-image a::before {
    background: rgba(1, 1, 1, 0.7) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease 0s;
    width: 100%;
}

.single-blog:hover .blog-image a::before {
    opacity: 1
}

.blog-image .date-time {
    background: #c9634c none repeat scroll 0 0;
    border-radius: 3px;
    bottom: 24px;
    color: #ffffff;
    display: inline-block;
    font-family: "Segoe UI Light";
    font-size: 24px;
    height: 60px;
    left: 28px;
    padding-top: 5px;
    position: absolute;
    text-align: center;
    width: 50px;
    transition: all 0.3s ease 0s
}

.blog-image .date-time span {
    background: #0185b9 none repeat scroll 0 0;
    border-radius: 0 0 3px 3px;
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    height: 25px;
    line-height: 26px;
    margin-bottom: 0;
    margin-top: 10px;
}

.blog-text {
    border-bottom: 1px solid #2f333d;
    border-left: 1px solid #2f333d;
    border-right: 1px solid #2f333d;
    padding: 31px 23px 28px;
    transition: opacity 0.3s ease 0s;
}

.single-blog:hover .blog-text {
    background: #ffffff none repeat scroll 0 0;
    border-color: #ebebeb;
    color: #7d7d7d !important
}

.blog-text > h3 {
    font-size: 18px;
    margin: 0 0 8px;
}

.blog-text > h3 a {
    color: #ffffff
}

.single-blog:hover .blog-text > h3 a {
    color: #363636
}

.blog-text > p {
    color: #c3c3c3
}

.single-blog:hover .blog-text > p, .single-blog:hover .blog-text a.blog-button {
    color: #7d7d7d
}

a.blog-button {
    color: #c3c3c3;
    display: inline-block;
    font-family: "Segoe UI Light";
    font-size: 16px;
    margin-top: 4px;
}

.single-blog .blog-text a.blog-button:hover, .single-blog:hover .blog-text > h3 a:hover, .blog-area.section-light-blog .single-blog .blog-text a.blog-button:hover {
    color: #c9634c
}

a.blog-button i {
    font-size: 17px;
    margin-left: 5px;
    position: relative;
    top: 1px;
}

.blog-area .button, .blog-area1 .button {
    background: #c9634c none repeat scroll 0 0;
    border: 1px solid #c9634c;
}

.blog-area .button:hover {
    color: #fff
}

.buttons-load {
    margin-top: 46px
}

/*--------------------------------------
	11. Download Area
----------------------------------------*/
.download-area {
    background: rgba(0, 0, 0, 0) url("../img/banner/download-bg.jpg") repeat scroll center center;
    padding-bottom: 200px;
    padding-top: 78px;
    position: relative
}

.download-area .section-title {
    margin-bottom: 42px
}

.download-area .section-title > h1 {
    margin-bottom: 22px;
}

.download-area .section-title p {
    color: #fff
}

.download-buttons .button {
    background: #ffffff none repeat scroll 0 0;
    border-color: #ffffff;
    color: #c9634c;
    margin: 0 15px;
    padding: 0 28px 0 54px;
    position: relative;
}

.download-buttons .button img {
    left: 26px;
    position: absolute;
    top: 10px;
    transition: all 0.3s ease-in 0s
}

.download-buttons .button img.btn-hover {
    display: none
}

.download-buttons .button:hover img.btn-hover {
    display: block
}

.download-buttons .button:hover {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-color: #ffffff;
    color: #ffffff;
}

/*--------------------------------------
	12. Contact Area
----------------------------------------*/
.contact-area .section-title {
    margin-bottom: 30px;
}

.contact-info-container {
    background: #ffffff none repeat scroll 0 0;
    margin-top: -110px;
    padding: 47px 36px 40px;
    position: relative;
}

.contact-area .section-title > h1 {
    color: #252830;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.contact-area .section-title > h1 span {
    color: #413d4b;
}

.contact-area .section-title > p {
    color: #898989;
    line-height: 20px;
    margin-bottom: 35px;
    padding-right: 98px;
}

.contact-address li {
    color: #797979;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 22px;
}

.contact-address span.icon-img {
    border: 2px solid #efefef;
    border-radius: 50%;
    display: inline-block;
    height: 48px;
    line-height: 44px;
    margin-right: 15px;
    text-align: center;
    width: 48px;
}

.contact-address span.icon-img img {
    vertical-align: middle
}

.contact-address .information {
    display: inline-block;
    line-height: 18px;
}

.contact-address ul li:last-child .information {
    position: relative;
    top: 11px;
}

#contact-form input, #contact-form select, .yourmessage {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #ebebeb;
    border-radius: 25px;
    box-shadow: none;
    color: #000;
    font-family: "Segoe UI Light";
    font-size: 17px;
    height: 46px;
    line-height: 46px;
    margin-bottom: 6px;
    padding: 0 24px;
    width: 100%;
}

textarea.yourmessage.form-control {
    height: 169px;
    resize: none;
    outline: none
}

textarea.yourmessage.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #ebebeb
}

.submit-form .button {
    background: #c9634c none repeat scroll 0 0;
    border-color: #c9634c;
    color: #ffffff;
    height: 48px;
    line-height: 48px;
    padding: 0 53px;
}

.submit-form .button:hover, .blog-area.section-light-blog .button:hover {
    background: transparent;
    border-color: #c9634c;
    color: #c9634c;
}

/*--------------------------------------
	13. Footer Area
----------------------------------------*/
footer {
    padding-bottom: 60px;
    padding-top: 70px;
}

.footer-area > p {
    color: #c3c3c3;
    font-size: 18px;
}

.footer-logo > a {
    display: inline-block;
    margin-bottom: 14px;
}

.social-link a {
    color: #aaa9a7;
    display: inline-block;
    font-size: 18px;
    margin: 0 7px;
    text-align: center;
    width: 20px;
}

.social-link a:hover {
    color: #ffffff
}

/*----------------------------------------------------------------
	14. Image, Solid, Gradient, Transparent, Video Background Area
-------------------------------------------------------------------*/
.slider-area {
    position: relative
}

.banner-content.static-text {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 999;
}

.banner-content.static-text.image-text .text-content .title1, .banner-content.static-text.image-text .text-content .title2, .banner-content.static-text.image-text .text-content .title3, .banner-content.static-text.image-text .text-content p {
    opacity: 0.65;
}

.banner-content.static-text.image-text .text-content .banner-button a {
    border-color: #d7d7d7
}

.banner-content.static-text.image-text .text-content .banner-button a:hover {
    border-color: #c9634c
}

.slider-area.solid-color {
    background: #c9634c;
    height: 493px
}

.slider-area.solid-color .banner-content .banner-button a:hover, .slider-area.transparent-color .banner-content .banner-button a:hover {
    background-color: #fff;
    border: 1px solid #fff;
    color: #c9634c;
}

.transparent-overlay {
    background: #c9634c none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
}

.video-overlay.gradient::after {
    background: url("../video/gradient.jpg");
    opacity: 0.8
}

.video-overlay.solid-color:after {
    background: #c9634c;
    opacity: 1
}

.video-overlay.transparent::after {
    background: #c9634c none repeat scroll 0 0;
    opacity: 0.8;
}

.download-area.gradient, .download-area.gradient.light {
    background: rgba(0, 0, 0, 0) url("../img/banner/download-bg-gradient.jpg") repeat scroll center center;
    padding-bottom: 200px;
    padding-top: 78px;
}

.download-area.solid-color {
    background: #c9634c
}

#slider .video-banner .banner-content h1 span {
    /*font-family: "montserratbold";*/
    font-family: "Segoe UI Black";
    font-size: 30px;
}

#slider .video-banner {
    height: 493px
}

/*--------------------------------------
	15. Light Section Style
----------------------------------------*/
.section-light-text p, .section-title.light p, .single-service.light .service-text > p, .section-light-text .feature-text .p-title {
    color: #888888
}

.feature-area.section-light-text, .clients-area.section-light-text {
    border-bottom: 1px solid #ebebeb
}

.section-light-text a.button:hover, .single-price-package.dark .price-list .button:hover {
    color: #fff
}

.section-light-text .feature-text > h2 {
    color: #242e4f
}

.section-light-text .feature-text > h1, .section-light-text .feature-list-text > h4, .section-light-text .single-feature-text h5, .single-service.light .service-text > h4 {
    color: #252830
}

.section-light-text .feature-text .button, .section-light-blog .blog-text > h3 a {
    color: #363636
}

.single-service.light .service-icon::after {
    border-color: #e2b7a7
}

.single-service.light .service-border::before, .single-service.light .service-border::after {
    background: #ebebeb
}

.text-white {
    color: #fff !important
}

.features-carousel-area.section-light-text .features-list h1 {
    color: #252525
}

.section-light-text .feature-list-carousel.owl-theme .owl-controls .owl-page span {
    background: #e1e1e1 none repeat scroll 0 0;
    border: 2px solid #fff;
}

.section-light-text .feature-list-carousel.owl-theme .owl-controls .owl-page::after, .section-light-text .feature-list-carousel.owl-theme .owl-controls .owl-page.active span, .section-light-text .feature-list-carousel.owl-theme .owl-controls .owl-page:hover span {
    background: #959595 none repeat scroll 0 0;
}

.blog-area.section-light-blog .single-blog .blog-text > p, .blog-area.section-light-blog .single-blog .blog-text a.blog-button {
    color: #888
}

.light .single-price-package {
    border-color: #e8e8e8
}

.light .single-price-package.dark {
    border-color: #252830
}

.section-light-text .testimonial-text-slider h2 {
    color: #272727
}

.section-light-blog .blog-text {
    border-color: #ebebeb
}

.download-area.light .section-title > h1, .download-area.light .section-title > h1 span {
    color: #fff
}

.download-area.solid-color.light .download-buttons .button:hover {
    background: transparent;
    border-color: #fff;
    color: #fff;
}

.contact-light .contact-info-container {
    border-bottom: 1px solid #f6f6f6
}

.contact-light .contact-info-container::before {
    background: #f6f6f6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 79.7%;
    left: 0;
    position: absolute;
    width: 1px;
}

.contact-light .contact-info-container::after {
    background: #f6f6f6 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 79.7%;
    right: 0;
    position: absolute;
    width: 1px;
}

.light-footer .footer-area > p {
    color: #888
}

.light-footer .social-link a {
    color: #898989
}

.light-footer .social-link a:hover {
    color: #252830
}

/*--------------------------------------------*/
/*  16. Layout Two Style
/*--------------------------------------------*/
.layout-two-menu .logo a {
    margin-left: 0px
}

.layout-two-slider .layer-1 img, .layout-two-video .layer-1 img {
    animation: 0.8s ease-in-out 0.5s normal backwards 1 running fadeInUp;
    bottom: 0;
    left: 10%;
    top: auto;
    width: 90%;
}

.layout-two-slider .banner-content .text-content, #slider.layout-two-video .video-banner .text-content {
    max-width: 88.65%;
    text-align: left;
    top: 26.5%;
}

.layout-two-slider .banner-content h1, .layout-two-video .banner-content h1 {
    font-size: 37px;
    margin-bottom: 16px;
}

.layout-two-slider .banner-content h2, .layout-two-video .banner-content h2 {
    font-family: "Segoe UI Light";
    font-size: 71px;
    line-height: 45px;
    margin-bottom: 20px;
}

.layout-two-slider .banner-content h3, .layout-two-video .banner-content h3 {
    font-size: 68px;
    margin-bottom: 0px;
}

.layout-two-slider .banner-content p, .layout-two-video .banner-content p {
    margin-bottom: 2px
}

.layout-two-slider .banner-content .banner-button, .layout-two-video .banner-content .banner-button {
    margin-top: 33px
}

.layout-two-slider .static-text .layer-1 img, .layout-two-slider.solid-color .layer-1 img, .layout-two-video .layer-1 img {
    position: absolute;
    right: 0;
    animation: none;
}

.layout-two-slider .banner-content.static-text.image-text .text-content, .layout-two-video .banner-content.static-text.image-text .text-content {
    opacity: 1
}

.slider-area.solid-color.layout-two-slider, #slider.layout-two-video .video-banner {
    height: 608px;
}

/*--------------------------------------------*/
/*  17. Scroll Up Start
/*--------------------------------------------*/
#scrollUp {
    background: rgba(0, 0, 0, 0) url("../img/icon/back-top.png") no-repeat scroll 50% 50%;
    bottom: 35px;
    cursor: pointer;
    display: none;
    height: 67px;
    position: fixed;
    right: 12px;
    width: 67px;
    z-index: 9999;
}

#scrollUp:hover {
    background: url("../img/icon/back-top-hover.png") no-repeat scroll 50% 50%;
}

/** Ajustando single-feature **/

.single-feature-text h5 {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 2px;
}

.single-feature > span {
    display: inline-block;
    float: left;
    margin-right: 20px;
    color: #c9634c;
}

.single-feature-text {
    /*display: inline-block*/
    position: relative;
    float: left;
    margin: 0px;
    width: 80%;
}

.single-feature {
    margin-bottom: 10px
}

.page .pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #c9634c;
    border-color: #c9634c;
}

.page .pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #c9634c;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}
.page .pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.news {
    color: #3c3c3c;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #f2f2f2;
}

.video_box {
    position: relative;
    float: left;
    margin: 0px 20px 20px 0px;
    padding: 0px;
    width: auto;
}

.video_box #image {
    z-index: 9;
    text-align: center;
    border: 1px solid blue;
}
.video_box #play {
    background: url("../img/icon/youtube-play-button.png") no-repeat;
    margin: -240px 0px 110px 165px;
    height: 128px;
    position: relative;
    z-index: 10;
}

/*----------------------------------------*/
/*  4. Pagina Area
/*----------------------------------------*/

.pagina-text > h2 {
    font-size: 35px;
    line-height: 42px;
    color: #959595;
}

.pagina-text > i {
    font-size: 16px;
    line-height: 23px;
    font-style: italic;
}

.pagina-text {
    color: #3c3c3c
}

.pagina-text > h1 {
    color: #959595;
}

.single-feature-text > h5 {
    color: #252830;
}

.single-feature-text > p {
    color: #888888;
}
.blog_feature {
    position: relative;
    float: right;
    margin: 0px 0px 0px 30px;
    padding: 0px;
    width: auto;
}

.blog_feature .feature-list > h4
{
    margin-top: 20px;
    margin-bottom: 20px;
    color: #959595 !important;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #959595 ;
}

.blog_feature .single-feature > span {
    display: inline-block;
    float: left;
    margin-right: 20px;
    width: 20px;
    color: #c9634c;
}

.feature-text {
    color: #888888
}

.feature-text > h4 {
    font-size: 20px;
    color: #252830;
}

.playlist {
    position:relative;
    float: left;

    margin:55px 0px 0px 0px;
    padding:0px 10px;

    background: #1E2127;
    color: #f2f2f2;
    width: 100%;
    height: 480px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.playlist > h5 {
    margin: 15px 0px;
}

.playlist > ul > li {
    border-bottom: 1px solid #c3c3c3;
}

.playlist > ul > li > a > h4, .playlist > ul > li > a > p  {
    color: #FFF !important;
}

.playlist > ul > li > a:hover > h4, .playlist > ul > li > a:hover > p   {
    color: #CCC !important;
}

.playlist > ul > li > a > p  {
    font-size: 14px;
}

#slider {
    overflow:hidden;
    height:690px;
}

#nivoslider img:not(:first-child) { display:none; }