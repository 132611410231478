.mt {
    margin-top: 10px;
}
.mt2 {
    margin-top: 20px;
}
.mt3 {
    margin-top: 30px;
}
.mt4 {
    margin-top: 40px;
}
.mt5 {
    margin-top: 50px;
}
.mt6 {
    margin-top: 60px;
}
.mb {
    margin-bottom: 10px;
}
.mb2 {
    margin-bottom: 20px;
}
.mb3 {
    margin-bottom: 30px;
}
.mb4 {
    margin-bottom: 40px;
}
.mb5 {
    margin-bottom: 50px;
}
.mb6 {
    margin-bottom: 60px;
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

.embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

img.foto {
    position: relative; float: left; margin: 0px; padding: 0px 20px 20px 0px;
}
